body {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #F0EFEF 100%) 0% 0% no-repeat padding-box;
}

.bg-app {
    @apply lg-max:bg-transparent from-white to-[#F0EFEF] lg-max:bg-gradient-to-b
}

* {
    @apply font-montserrat-regular;

}


.container {
    @apply mx-auto max-w-1140;
}

@media only screen and (min-width: 1140px) {
    .user::after {
        content: '|';
        position: absolute;
        top: 0;
        margin-left: 4px;
        right: -12px;


    }
}


.btn {
    @apply text-11 text-white rounded-20 font-montserrat-bold text-center transition ease-in-out duration-500
}


.btn-red {
    @apply bg-red border-0.4 border-red;

    &:hover {
        @apply bg-white text-red
    }
}

.btn-brown {
    @apply bg-secondary-brown border-0.4 border-secondary-brown;

    &:hover {
        @apply bg-white text-main-brown
    }
}

.btn-white {
    @apply bg-white
}

.btn-gray {
    @apply bg-light-gray border-0.4 border-light-gray;

    &:hover {
        @apply bg-opacity-40 border-main-brown
    }
}



.btn-m {
    @apply py-[5.5px] max-w-187 w-full whitespace-nowrap
}

.btn-s {
    @apply py-[5.5px] px-[33.7px] whitespace-nowrap
}

.btn-xs {
    @apply py-[4.5px] max-w-[121px] whitespace-nowrap
}

.sm-font-b {
    @apply font-montserrat-bold text-11 text-main-brown
}

/*---------INPUTS----------*/
.input-form {
    @apply border-0.4 border-main-brown rounded-[10px] w-full block leading-6 h-10 px-3
}

.input-focus {
    @apply focus:outline-none focus:border-main-brown focus:ring-1 focus:ring-main-brown
}

.input-transition {
    @apply transition ease-in-out duration-500
}

.input-text-l {
    @apply text-sm xs:text-base text-main-brown font-montserrat-bold
}

.input-placeholder {
    @apply placeholder:text-gray placeholder:opacity-30 placeholder:tracking-1.6
}

.input-placeholder-serach {
    @apply placeholder:text-11 placeholder:pl-[38px] placeholder:text-main-brown
}

.input-checkbox {
    @apply h-5 w-5 rounded-[3px] focus:ring-transparent border-0.4 border-main-brown text-main-brown focus:outline-none cursor-pointer
}

.input-file {
    @apply file:hover:bg-opacity-50 file:transition file:duration-500 file:ease-in-out file:cursor-pointer cursor-pointer file:-mx-3 file:-my-[0.32rem] file:h-10 file:text-white file:bg-main-brown file:border-0 file:border-solid file:px-3 file:py-[0.32rem] file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] file:overflow-hidden
}

.placeholder {
    @apply placeholder:font-montserrat-bold placeholder:text-main-brown md:placeholder:opacity-0
}

.select-state {
    & [type='text']:focus {
        box-shadow: none !important;
    }
}


.css-1u9des2-indicatorSeparator {
    display: none;
}

.css-tj5bde-Svg {
    width: 1.3em !important;
    height: 1.3em !important;
    fill: #6b7280 !important;
}

.css-lkh0o5-menu {
    border-radius: 10px !important;
    margin-top: 0 !important;
}

@media only screen and (max-width: 768px) {
    .css-1jqq78o-placeholder {
        font-family: 'Montserrat-bold', sans-serif !important;
        color: #6B6145 !important;
    }
}




/*------------FONT------------------ */
.font-basic {
    @apply text-11 text-main-brown
}

.font-basic-black {
    @apply text-11 text-light-black
}

.font-think {
    @apply font-montserrat-regular
}

.error-text {
    @apply md:text-[9px] text-[8px] py-2 text-red font-montserrat-bold
}


/*------------Hover------------------ */
.list-item-drop {
    & div {
        & a:hover {
            @apply hover:bg-light-gray hover:font-bold
        }
    }
}


.h-transition {
    @apply transition-all duration-500
}

.bg-item {
    @apply bg-light-gray rounded-lg shadow-s-shadow
}

.bg-item-hov {
    @apply hover:bg-light-gray hover:rounded-lg hover:shadow-s-shadow
}

.link-hover {
    @apply hover:before:scale-x-100 hover:before:origin-left before:w-full before:h-[0.4px] before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:absolute before:left-0 before:-bottom-[2px] relative
}

.link-black {
    @apply before:bg-light-black
}

.link-red {
    @apply before:bg-red
}

.link-green {
    @apply before:bg-green-500
}


/*--------------ICON--------------- */
.voucher-icon {
    @apply w-4 h-2.5 xs:left-[-110px] left-[-95px] opacity-30 translate-y-[-50%] top-[20px] absolute
}


.lupa-icon {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    left: 15px;
}

.list-items {
    @apply transition-all duration-500;

    &:hover {
        @apply bg-light-gray cursor-pointer
    }

    .btn {
        @apply transition-all duration-500;
    }

    &:hover .btn {
        @apply opacity-100
    }

}

/*--------------HAMBURGERN BUTTON --------------- */


#nav-icon {
    width: 36px;
    height: 36px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 1.5px;
    width: 100%;
    @apply bg-main-brown;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}


/* Icon 4 */

#nav-icon span:nth-child(1) {
    top: 3px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon span:nth-child(2) {
    top: 9px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon span:nth-child(3) {
    top: 15px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 2px;
    left: 3px;
}

#nav-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 16px;
    left: 3px;
}

.span-box {
    height: 60%;
    position: absolute;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid transparent;
}

.button {
    border: none;
    background-color: transparent;

}

/*--------------LEFT SIDEBAR --------------- */
.left-sidebar {
    @apply min-w-[260px] flex grow flex-col border-r-2 rounded-l-20 bg-white z-10 border-main-brown pl-[41px] pr-[30px] pt-14 pb-[38px] border-opacity-15 lg:relative absolute -left-96 lg:left-0 lg:h-auto h-full lg:transition-none transition-all duration-500;
}

.show {
    @apply rounded-tl-[10px] left-0;
}

.custom-height {
    height: calc(100vh - 152px);
    overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
    .custom-height {
        height: auto;
    }
}