@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Montserrat-Regular';
    src: url("./assets/fonts/Montserrat-Regular.ttf") format('truetype');
}
@font-face {
    font-family: 'Montserrat-Bold';
    src: url("./assets/fonts/Montserrat-Bold.ttf") format('truetype');
}
